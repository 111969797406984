


const OverruleDraft = () => {
    return (
      <>
      <div style={{ backgroundColor:'#f9f9f9' }}> 
        <br></br>
        <iframe
        title="Overrule Draft"
        aria-label="Overrule Draft"
        style={{ height: '100vh', width: '100vw', border: 'none', margin: 0, padding: 0 }}
        src="https://forms.zohopublic.com/overrule/form/OverruleDraftShop/formperma/NhWX0Mg5qR3v7eF2tzA2Q6XUXW1jvbuFZf-yzbY9_L0"
      ></iframe>
      </div>
      </>
    );
  };
  
  export default OverruleDraft;