import logo from './logo.png';
import './App.css';
import LottieAnimation from './LottieAnimation'
import ZohoForm from './ZohoForm';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Draft from './OverruleDraft';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<>
      <div className="container">
        <LottieAnimation />
       {/* <ZohoForm /> */}
          {/* <button type='button' id="zf_button_756606">Form</button> */}

      </div>
    </>} />
        <Route path="/createdraft" element={<Draft />} />
      </Routes>
    </Router>

    
  );
}

export default App;
